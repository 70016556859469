import httpapi from '../../utils/httpapi';
const accountApi = {
	//查询单位下的所有公众号
	list(funsucc) {
		httpapi.get('/customer/account/mp/list', {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	//已有小程序添加小程序
	addwxapp(param, funsucc) {
		httpapi.postjson('/customer/account/mp/new/wxapp/add', param).then(res => {
			console.log(res, 'add.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//无小程序添加小程序
	addwxappreg(param, funsucc) {
		httpapi.postjson('/customer/account/mp/new/wxapp/reg', param).then(res => {
			console.log(res, 'add.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//微信:添加公众号
	addgzh(param, funsucc) {
		httpapi.postjson('/customer/account/mp/new/wxgzh/add', param).then(res => {
			console.log(res, 'addgzh.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//微信:添加企业微信
	addqywxdevp(param, funsucc) {
		httpapi.postjson('/customer/account/mp/new/wxwork/add/devp', param).then(res => {
			console.log(res, 'addqywx.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	addqywxisv(param, funsucc) {
		httpapi.post('/customer/account/mp/new/wxwork/add/isv', param).then(res => {
			console.log(res, 'addqywx.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	addqywxproxy(param, funsucc) {
		httpapi.post('/customer/account/mp/new/wxwork/add/proxy', param).then(res => {
			console.log(res, 'addqywx.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//支付宝:添加小程序
	addzfbapp(param, funsucc) {
		httpapi.postjson('/customer/account/mp/new/zfbapp/add', param).then(res => {
			console.log(res, 'addzfbapp.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//支付宝:添加生活号
	addzfblife(param, funsucc) {
		httpapi.postjson('/customer/account/mp/new/zfbgzh/add', param).then(res => {
			console.log(res, 'addzfblife.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//设置公众号密钥
	gzhsecretset(param, funsucc) {
		httpapi.postjson('/customer/account/mp/appsecret/set', param).then(res => {
			console.log(res, 'edit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//编辑公司档案信息->需要注册微信小程序调用
	appedit(param, funsucc) {
		httpapi.postjson('/customer/account/mp/comp/edit', param).then(res => {
			console.log(res, 'config.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//修改公众号名称
	updategzh(param, funsucc) {
		httpapi.post('/customer/account/mp/mpname/set', param).then(res => {
			console.log(res, 'updategzh.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//查询公众号信息
	info(mpid, funsucc) {
		httpapi.post('/customer/account/mp/detail', mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//微信开放平台扫码授权url
	wexinauthurl(mpid, funsucc) {
		console.log(mpid,'mpid');///customer/wxgzh/weixin/auth/geturl
		httpapi.get('/customer/wxgzh/weixin/auth/geturl?mpid='+mpid, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//微信开放平台用户扫码后授权回调
	weixinauthcall(mpid,auth_code,funsucc){
		httpapi.get('/customer/wxgzh/weixin/auth/call?mpid='+mpid+'&auth_code='+auth_code, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},

	//企业微信扫码授权url
	qywxauthurl(mpid, funsucc) {
		console.log(mpid,'mpid');///customer/wxgzh/weixin/auth/geturl
		httpapi.get('/customer/qiyeweixin/account/auth/geturl?mpid='+mpid, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//企业微信扫码扫码后授权回调
	qywxauthcall(mpid,auth_code,funsucc){
		httpapi.get('/customer/qiyeweixin/account/auth/call?mpid='+mpid+'&auth_code='+auth_code, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},

	//注册可选择的开放平台
	allauth(opentype,funsucc){
		httpapi.get('/customer/account/mp/openplat/list?opentype='+opentype, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	//使用token自动登录
	token(param,funsucc){
		httpapi.post('/customer/auth/user/login/token', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	
	regwxapp(param,funsucc){
		httpapi.post('/customer/wxapp/open/weixin/app/gzh/regwxapp', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	querywxapp(param,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/gzh/querywxapp?ticket=' + param.ticket + '&wxgzh_mpid=' + param.wxgzh_mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	},
	getteseterlist(mpid,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/tester/list?mpid=' + mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
		
	},
	testerbind(param,funsucc){
		console.log(param,'bind.param');
		httpapi.post('/customer/wxapp/open/weixin/app/tester/bind',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
		
	},
	updatamemo(param,funsucc){
		httpapi.post('/customer/wxapp/open/weixin/app/tester/updatememo',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	},
	flushfromwx(mpid,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/tester/flushfromwx?mpid='+ mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	coderhistory(mpid,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/coder/version/history?mpid=' + mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	},
	coderonline(mpid,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/coder/version/online?mpid=' + mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	},
	coderauditlasted(mpid,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/coder/audit_lasted?mpid=' + mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	tempaltelist(mpid,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/template/list?mpid=' + mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	},
	tempaltelist(mpid,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/template/list?mpid=' + mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	},
	//生成体验版
	committest(param,funsucc){
		httpapi.post('/customer/wxapp/open/weixin/app/coder/commit/jbclubapp',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	getappewm(mpid,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/coder/testqr?mpid=' + mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	getprivacy(mpid,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/coder/privacyget?mpid=' + mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	saveprivacyset(param,funsucc){
	console.log(param,'saveprivacyset.param');
		httpapi.postjson('/customer/wxapp/open/weixin/app/coder/privacyset',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	uploadmediatocodeaudit(param,funsucc){
		console.log(param,'uploadmediatocodeaudit.param');
		httpapi.handleUploadFile('/customer/wxapp/open/weixin/app/coder/uploadmediatocodeaudit',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	audit_submit(param,funsucc){
		httpapi.postjson('/customer/wxapp/open/weixin/app/coder/audit_submit',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	audit_cancel(mpid,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/coder/audit_cancel?mpid=' + mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	verback(param,funsucc){
		httpapi.get('/api/customer/wxapp/open/weixin/app/coder/hisrevert?mpid=' + param.mpid + '&version=' + param.version).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	getwxworkmpid(funsucc) {
		httpapi.get('/customer/account/mp/wxwork/getmpid').then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	getwxwrokuserinfo(param,funsucc) {
		httpapi.post('/openapi/qyweixin/api/login/auth2/code/getuserinfo',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	
	
}
export default accountApi