<template>
	<div class="content">
		<div class="table">
			<div class="thead">
				<div class="th">
					<div class="td" v-for="(item,index) in titlelist" :key="index">
						{{item.title}}
					</div>
				</div>
			</div>
			<div class="tbody">
				<div class="th" v-for="(item,index) in menulist" :key="index">
					<!-- <div class="td">
						{{item.appid}}
					</div> -->
					<div class="td">
						{{item.mpname}}
					</div>
					<div class="td">
						{{item.createdate}}
					</div>
					<div class="td info" @click="myMenuClick(item)">
						<span>详情</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 九宫格菜单功能区 -->
		<!-- <div class="upView" style="background-color: #F3F3F3;">
			<sudokuMenu class="nineV" @menuClick="myMenuClick(item)" :myFlag="item.flag" :myText='item.mpname'
				v-for="(item,index) in menulist" :key="index" :imgSrc='item.img'>
			</sudokuMenu>
		</div> -->
		<!-- <div class="upView" style="background-color: #F3F3F3;">
			<sudokuMenu class="nineV" @menuClick="myMenuClick" :myFlag="item.flag" :myText='item.text'
				v-for="(item,index) in menudata" :key="index" :imgSrc='item.img'>
			</sudokuMenu>
		</div> -->
		<hansTabber :list="list" :currentIndex="0" style="position:fixed;bottom:0;width:100%;left:0;right:0;"
			@tabChange="tabChange">
		</hansTabber>
	</div>
</template>

<script>
	// 导入请求方法
	import accountApi from '@/api/cus/account.js'
	import sudokuMenu from '@/components/sudokuMenu'
	import hansTabber from '@/components/hans-tabbar/hans-tabbar.vue'
	import {getloginuser} from '../../../utils/util.js'
	export default {
		components: {
			sudokuMenu,
			hansTabber
		},
		data() {
			return {
				menulist: [],
				titlelist: [
					// {id:0,title:"公众号名称"},
					{
						id: 1,
						title: "应用名称"
					},
					{
						id: 2,
						title: "创建时间"
					},
					{
						id: 3,
						title: "操作"
					},
				],
				currentIndex: 0,
				list: [{
						"text": "首页",
						"iconPath": require('@/assets/app/home.png'),
						"selectedIconPath": require('@/assets/app/home_active.png')
					},
					{
						"text": "模版市场",
						"iconPath": require('@/assets/app/work.png'),
						"selectedIconPath": require('@/assets/app/work_active.png')
					},
					{
						"text": "我的",
						"iconPath": require('@/assets/app/my.png'),
						"selectedIconPath": require('@/assets/app/my_active.png')
					}
				],
				userInfo: {},
				appData: []
			}
		},
		mounted() {
			let u = getloginuser()
			// let u = sessionStorage.getItem('customeruser')
			console.log(u, 'uuu');
			if (u != null) {
				this.userInfo = u;
				console.log(this.userInfo, 'this.userInfo');
				this.getdata(this.formInline)
			} else {
				this.$router.push("/app/qiyewx/login");
			}
		},
		methods: {
			// 获取数据方法
			getdata(page) {
				// 请求数据开始
				accountApi.list(res => {
					console.log(res, 'accountApi.res');
					if (res.code == 200) {
						this.menulist = res.data
						console.log(this.menulist, 'v');
						// this.menulist = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			myMenuClick(row) {
				console.log(row, 'row');
				let path = ''
				switch (row.mptype) {
					case 10:
						//微信公众号
						path = '/app/qiyewx/list/info'
						break;
					case 11:
						//注册小程序
						path = '/app/qiyewx/list/info'
						break;
					case 12:
						//企业微信
						path = '/app/qiyewx/list/info'
						break;
					case 21:
						//支付宝小程序
						path = '/app/qiyewx/list/info'
						break;
					default:
						break;
				}
				this.$router.push({
					path: path,
					query: {
						mpid: row.mpid
					}
				})
			},
			tabChange(index) {}
		}
	}
</script>

<style lang="scss">
	.content {
		/* display: flex;
		flex-direction: column; */
		height: 100vh;
		overflow-y: auto;
	}

	.table {
		display: flex;
		flex-direction: column;

		.thead {
			.th {
				display: flex;

				.td {
					width: 33.3%;
					height: 40px;
					text-align: center;
					line-height: 40px;
					background-color: #f0f0f0;
					border-bottom: 1px solid #EBEEF5;
				}
			}
		}

		.tbody {
			.th {
				display: flex;

				.td {
					width: 33.3%;
					height: 40px;
					text-align: center;
					line-height: 40px;
					font-size: 12px;
					border-bottom: 1px solid #EBEEF5;
				}

				.info span{
					width: 50px;
					display: inline-block;
					line-height: 30px;
					white-space: nowrap;
					cursor: pointer;
					background: #FFF;
					border: 1px solid #DCDFE6;
					color: #606266;
					text-align: center;
					box-sizing: border-box;
					outline: 0;
					margin: 0;
					border-radius: 5px;
					transition: .1s;
					font-weight: 500;
				}
			}
		}
	}

	.upView {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-left: 12px;
		width: calc(100vw - 24px);
		margin-top: 10px;
		padding: 4px 0px;
		background-color: white;

	}

	.headLine {

		height: 30px;
		line-height: 30px;
		margin: 10px 15px;
		font-size: 20px;
	}

	.nineV {
		width: calc((100vw - 24px)/3);
	}
</style>